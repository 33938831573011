import React, { useState } from 'react'
import "./BookAppointment.css"
import arrow from '../assets/images/btn-arrow.svg';
import axios from 'axios';

export const BookAppointment = () => {

  const [formData, setFormData] = useState({
    listingOptions: '',
    areaOptions: '',
    budget: '',
    traits: '',
    firstname: '',
    isLead:1,
    lastname: "",

    address1: "",
    isLead:true,
   
    provinceName: "",
    source: "Website",
    phone: "",
    parentId: null,
    createdAt: "",
    updatedAt: "",
    realtorId: null,
    propertyId: null,
    children: [],
    email: '',
    phone: '',
  });
  const url = process.env.REACT_APP_API_URL;
  const[formSubmitted,setFormSubmitted]=useState(false)
    const [validationErrors, setValidationErrors] = useState({

      name: '',
     
    });
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValidationErrors({
          
      })
      setFormData({ ...formData, [name]: value });
    };
    const handleSubmit =async (e) => {
      e.preventDefault();
  
      const errors = {};
  
     
      if (!formData.firstname) {
        errors.firstname = 'Name is required';

      }
  
   
  
     
  
      if (Object.keys(errors).length > 0) {
          console.log(errors)
        setValidationErrors(errors);
      } else {
          const response = await axios.post(`${url}api/contacts/create`, formData)
        // Form data is valid, you can perform any further actions here
        console.log('Form data submitted:', formData);

        setFormData({
          
              listingOptions: '',
              areaOptions: '',
              budget: '',
              traits: '',
              firstname: '',
              isLead:1,
              message:'',
              lastname: "",
              birthDate: "",
              address1: "",
              isLead:true,
              address2: "",
              city: "",
              provinceName: "",
              source: "",
              phone: "",
              parentId: null,
              createdAt: "",
              updatedAt: "",
              realtorId: null,
              propertyId: null,
              children: [],
              email: '',
              phone: '',
            
        })
        setFormSubmitted(true)

      }
    };




  return (
    <div className='bookappointment-wrapper'>
        <div className='container-main bookappontment-container'>
            <div className='bookappointment-block'>
                <div className='book-apointment-top-txt'>
                    <h2>Book an appointment</h2>
                    <p>Please enter your contact information and we will have a representative reach out to you shortly.</p>
                </div>

             <div className='bookapointment-form'>
             {!formSubmitted? <form onSubmit={handleSubmit}>
                    <div className='bookapointment-form-input-sec'>
                    <input  
                    value={formData.firstname}
                  onChange={handleInputChange}
                  name="firstname"
                 type='text'
                  placeholder='Enter your name here'/>
                    <input type='text' 
                     name="email"
                     value={formData.email}
                     onChange={handleInputChange}   
                    placeholder='Enter your email here'/>
                    <textarea
                    name="message"
                      value={formData.message}
                      onChange={handleInputChange} 
                    placeholder='Enter your message here'></textarea>
                    </div>

                    <div className='submit-wrapper-simp'>
                      <button type="submit" className='button-arrow-dark'>Submit <img className='arrow-n-btn' src={arrow} alt="My Image" /></button>
                    </div>
                    </form>:
                     
                     <form className='form-submit-message'>
                       <img className='tick-img' src='/tick.png' alt="My Image" />
                       <p>Form Submitted successfully</p>
                       </form>}
                       
                </div>
                
              
            </div>
        </div>
    </div>
  )
}

export default BookAppointment
