import React, { useEffect, useState } from 'react'
import '../Feature-Listing/FeaturedListing.css'


import ListingHeader from '../ListingHeader/ListingHeader'
import { Form } from 'react-bootstrap'
import Select from 'react-select';
import FeatureChild from '../Feature-Listing/FeatureChild';

const ExclusiveListing = (props) => {
  const[toggle,setToggle]=useState('all')
  const[listings,setListings]=useState(props.listings)
  const [selectedOption, setSelectedOption] = useState(  { value: '1', label: 'Default Order' },);
  const options = [
    { value: '1', label: 'Default Order' },
    { value: '2', label: 'Price - Low to High' },
    { value: '3', label: 'Price - High to Low' },
  ];
useEffect(()=>{
if(toggle=='active'){
 const filtered= props.listings.filter((object) => object.status == 1)
 setListings(filtered)
}
else if(toggle=='sold'){
  const filtered= props.listings.filter((object) => object.status == 11)
  setListings(filtered)
 }
 else {
  setListings(props.listings)
 }

},[toggle])

const colourStyles = {
  control: styles => ({ ...styles, background: 'none', border: 'none' }),
  menu:styles => ({ ...styles, background: '#000' }),
  input:styles => ({ ...styles, width: '190px' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    color: isSelected ? '#fff' : '#fff',
    background:isSelected?"#c79a4f":"#222",
  }),
  singleValue: (styles, { data, isDisabled, isFocused }) => ({
    ...styles,
    color: '#000', // Set the text color of the selected value to white
  }),
};
useEffect(()=>{
 
  setListings(props.listings)
  },[props])
  // Create a state to store the selected option


  // Handle the selection change
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  
    // Create a copy of the listings array to avoid mutating the state directly
    const updatedListings = [...listings];
  
    if (selectedOption.value === '2') {
      // Sort by Price - Low to High
      updatedListings.sort((a, b) => a.price - b.price);
    } else if (selectedOption.value === '3') {
      // Sort by Price - High to Low
      updatedListings.sort((a, b) => b.price - a.price);
    } else {
      updatedListings.sort((a, b) => b.id - a.id);
      // Default Order (e.g., don't apply any sorting)
      // You can also fetch the default order from your original data if needed.
    }
  
    // Update the listings state with the sorted array
    setListings([...updatedListings]); // Make sure to spread the sorted array when updating the state
  };
  
  
  return (
    <div className='listing-indi-page'>
      <ListingHeader 
      title="Exclusive Listings"
      description="Finding your dream home is only a few clicks away"
      />

      <div className='property-filter-bar'>
        <div className='container-main container-main-about-sec-tw'>
             <div className='property-filter-bar-wrapper'>
                <div className='counting-filter'>
                   <p>{listings?.length} Results</p>
                </div>
                <div className='tab-filter'>
            <button
                className={`tab-button ${toggle === 'all' ? 'active' : ''}`}
                onClick={() => {
                    setToggle('all');
                }}
            >
                All
            </button>
            <button
                className={`tab-button ${toggle === 'active' ? 'active' : ''}`}
                onClick={() => {
                    setToggle('active');
                }}
            >
                Active
            </button>
            <button
                className={`tab-button ${toggle === 'sold' ? 'active' : ''}`}
                onClick={() => {
                    setToggle('sold');
                }}
            >
                Sold
            </button>
        </div>
                <div className='tab-select'>
                  <p>Sort By:</p>
                <Select
        options={options}
    
        value={selectedOption}
        styles={colourStyles}
        onChange={handleSelectChange}
        
      />
                </div>
             </div>
        </div>
      </div>

      <FeatureChild listings={listings}/>
    </div>
  )
}

export default ExclusiveListing