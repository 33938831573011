import React, { useEffect, useState } from 'react'
import './Accomplishment.css'
import Carousel from 'react-bootstrap/Carousel';
import award from '../assets/images/award.svg';
import award2 from '../assets/images/award2.svg';
import award3 from '../assets/images/award3.svg';

import award4 from '../assets/images/accomp-4.png';
import award5 from '../assets/images/accomp-5.png';
import award6 from '../assets/images/accomp-6.png';
import award7 from '../assets/images/accomp-7.png';
import award8 from '../assets/images/accomp-8.png';
import award9 from '../assets/images/accomp-9.png';
import award10 from '../assets/images/accomp-10.png';
import axios from 'axios';

const Accomplishment = (props) =>{
    const [awards, setAwards] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              headers: {}
            };
            
            const response = await axios.get('https://op.c21.ca/AwardsWidget/MjAxNjA4MDMwMTQ=?referrer', config);
            setAwards(response.data);
          } catch (error) {
            console.log(error);
          }
        };
    
        fetchData();
      }, []);
      const extractData = () => {
        // Select all elements with class "opBox"
        const opBoxes = document.querySelectorAll('.opBox');
  
        // Array to store extracted data
        const awardsData = [];
  
        // Regular expression to extract year from the inner HTML of <p> tags with class "opBox"
        const yearRegex = /(\d{4})/;
  
        // Iterate over each .opBox element
        opBoxes.forEach(box => {
          // Find the year from the inner HTML
          const yearMatch = yearRegex.exec(box.innerHTML);
          const year = yearMatch ? yearMatch[1] : null;
  
          // Find the image URL
          const imgElement = box.querySelector('img');
          const imgUrl = imgElement ? imgElement.getAttribute('src') : null;
  
          // Push data to awardsData array
          if (year && imgUrl) {
            awardsData.push({ year: year, imgUrl: imgUrl });
          }
        });
  
        // Log or use awardsData array as needed
      //  console.log(awardsData);
      };
//console.log(awards);
    const arr=[award,award2,award3]
    const arr2=[award4,award5,award6,award7]
    const arr3=[award8,award9,award10]
  return (
    <div className='accomplishment'>
        <div className='accomplishment-overlay'></div>
        <div className={`container-main ${props.slider ? "" : "acoomplisment-page-awwards"}`}>
            <div className='second-sec-txt-wrap'>
                <h2>ACCOMPLISHMENTS</h2>
            </div>

  {!props.slider&&
  <div className='accomplisment-page-imges-wrapper'>
    <div className='accomplisment-page-imges'>
  {arr.map((img, index)=> ( 
    <div className='images-cnd'>
        <img key={index} src={img} alt="My Image" />
    </div>     
    )) }
    </div>
    <div className='accomplisment-page-imges accomplisment-page-imges-center'>
  {arr2.map((img, index)=> ( 
    <div className='images-cnd'>
        <img key={index} src={img} alt="My Image" />
    </div>     
    )) }
    </div>
    <div className='accomplisment-page-imges'>
    {arr3.map((img, index)=> ( 
    <div className='images-cnd'>
        <img  key={index} src={img} alt="My Image" />
    </div>     
    )) }
    </div>
  </div>}
 


      { props.slider&&<div className='accomplishment-slides accomplishment-slides-dsktp'>
                <Carousel>
                <Carousel.Item>
                    <div className='carasaul-img-wrapper'>
                      {arr.map((img , index)=>(
                          <img key={index} src={img} alt="My Image" />
                      )) }
           
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carasaul-img-wrapper'>
                    {arr2.map((img , index)=>(
                        <img key={index} className='logo-awrd' src={img} alt="My Image" />
                        )) }
              
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carasaul-img-wrapper'>
                    {arr3.map((img, index)=>(
                        <img key={index} src={img} alt="My Image" />
                        )) }
                    </div>
                </Carousel.Item>
                </Carousel>
            </div>}

           { props.slider&&  <div className='accomplishment-slides accomplishment-slides-mobile'>
                <Carousel>
                {[...arr,...arr2,...arr3].map((img, index)=> ( <Carousel.Item  key={index}> 
                    <div className='carasaul-img-wrapper'>
                        <img  src={img} alt="My Image" />
                    </div>
                </Carousel.Item>
                    )) }
              </Carousel>
            </div>}
        </div>
    </div>
  )
}

export default Accomplishment