import React from 'react'
import "./Footer.css"
import Nav from 'react-bootstrap/Nav';
import logo from '../assets/images/24klogo.svg';
import fb from '../assets/images/social1.svg';
import twitter from '../assets/images/social2.svg';
import insta from '../assets/images/social3.svg';
import youtube from '../assets/images/youtube.svg';
import linkdin from '../assets/images/linkdin.svg';

import cntIco1 from '../assets/images/contact-icon.svg';
import cntIco2 from '../assets/images/contact-icon2.svg';
import cntIco3 from '../assets/images/contact-icon3.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import arrowlong from '../assets/images/arrow-long.svg';

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footer-main'>
        <div className='footer-top'>
            <div className='container-main'>

                <Row className='align-items-center footer-top-area-blck'>
                    <Col className='footer-logo' lg={3}><img src={logo} alt="My Image" /></Col>

                    <Col className='' lg={6}>
                       <Row>
                        <Col lg={12}>
                          <div className='newsletter-wrapper'>
                            <h3>Join Our Newsletter</h3>
                            <form className='newsletter-form'>
                              <input type="email" placeholder='Enter you email'/>
                              <button type="submit" className='listing-form-btn'>Subscribe <img className='arrow-n-btn' src={arrowlong} alt="My Image" /></button>
                            </form>
                          </div>
                        </Col>
                       </Row>
                    </Col>

                    <Col className='' lg={3}>
                    <div className='social-icons'>
                    <a href="https://www.facebook.com/RealtorKunalBharatendu" target='_blank'><img className='' src={fb} alt="My Image" /></a>
                    <a href="https://twitter.com/kunalbharatendu" target='_blank'><img className='' src={twitter} alt="My Image" /></a>
                    <a href="https://www.instagram.com/realtor_millennialgroup/" target='_blank'><img className='' src={insta} alt="My Image" /></a>
                    <a href="https://www.instagram.com/realtor_millennialgroup/" target='_blank'><img className='' src={youtube} alt="My Image" /></a>
                    <a href="https://www.instagram.com/realtor_millennialgroup/" target='_blank'><img className='' src={linkdin} alt="My Image" /></a>
                    </div>
                    </Col>
                </Row>

                <Row className='align-items-center footer-btm-area-clr'>
                    <Col className='' lg={6}>
                       <Row className='footer-btm-nav-sec-grey'>
                          <Col className='quick-links' lg={4}>
                            <h4>Quick Links</h4>

                            <Nav className="footer-nav-link">
                                    <Link to="/featured-listing" className='nav-link'>Featured Listings</Link>
                                    <Link to="/" className='nav-link'>MLS Search</Link>
                                    <Link to="/meet-our-team" className='nav-link'>Meet Our Team</Link>
                                    <Link to="/accomplishments" className='nav-link'>Accomplishments</Link>
                                    <Link to="/exclusive-listings" className='nav-link'>Exclusive Listings</Link>
                            </Nav>
                          </Col>

                          <Col className='quick-links' lg={8}>
                            <h4>Get in touch</h4>

                            <Nav className="footer-nav-link footer-nav-link2">
                                    <Link  className='nav-link'><img src={cntIco1} alt="My Image" /> #118 Ground Floor Little India Plaza 8028 128 St, Surrey, BC, V3W 4E9</Link>
                                    <a href='tel:+16047156516' className='nav-link'><img src={cntIco2} alt="My Image" /> 778.592.4312</a>
                                    <a href='mailto:info@24krealty.ca'  className='nav-link'><img src={cntIco3} alt="My Image" /> info@24krealty.ca</a>
                            </Nav>
                          </Col>
                       </Row> 
                    </Col>

                    <Col className='' lg={6}>
                      <iframe className="map-iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d334048.3160731779!2d-122.866732!3d49.148324!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d982b84dae8b%3A0x10afc17b4c57596e!2s8028%20128%20St%20%23%20118%2C%20Surrey%2C%20BC%20V3W%204E9%2C%20Canada!5e0!3m2!1sen!2sus!4v1627377171146!5m2!1sen!2sus" width="100%" height="230" allowfullscreen="" loading="lazy"></iframe>
                    </Col>
                </Row>
            </div>
        </div>

        <div className='footer-bar'>
            <div className='container-main'>
                <div className='footer-bar-cnt'>
                   
                    <p className='copyright-txt'>© {new Date().getFullYear()} 24k Realty Group, All Rights Reserved. Contents of this website, including the photography & videos, may not be used without written consent from 24k Realty Group. Maximum effort is put towards the details but cannot guarantee, buyer to verify all details.</p>
                   

                    <p className='developed_by'>Developed by: <a target="_blank" href='https://www.webastral.com/'>WebAstral InfoSystems</a></p>                  
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer