import React from 'react'
import './Listing.css'
import { Link } from 'react-router-dom'
import { useState } from 'react';

import property from '../assets/images/property-img.png';
import bed from '../assets/images/bed.svg';
import paper from '../assets/images/paper.svg';
import area from '../assets/images/area.svg';
import Modal from 'react-bootstrap/Modal';

 const Listing = (props) => {
const [showStatus,setShowStatus]=useState([])
const [show, setShow] = useState(false);
  const statusOptions = [
    { id: 1, name: "Active", color: "#1A8B17" },
    { id: 2, name: "Foreclosures", color: "red" },
    { id: 3, name: "For Rent", color: "blue" },
    { id: 4, name: "For Sale", color: "#e09200" },
    { id: 5, name: "Lease", color: "orange" },
    { id: 6, name: "New Construction", color: "#9d5a04" },
    { id: 7, name: "New Listing", color: "pink" },
    { id: 8, name: "Open House", color: "teal" },
    { id: 9, name: "Reduced Price", color: "indigo" },
    { id: 10, name: "Resale", color: "lime" },
    { id: 11, name: "Sold", color: "#A91212" },
    { id: 12, name: "Land", color: "gray" },
    { id: 13, name: "Owner Occupied", color: "olive" },
  ];

  const getStatus=(status)=>{
    
if(status==8){
  return statusOptions[0].name
}

    return statusOptions[status-1].name
  }
  const getColor=(status)=>{

    if(status==8){
      return statusOptions[0].color
    }
    return statusOptions[status-1].color
  }
const handleShowStatus=(index)=>{

  const newArray = showStatus.filter(item => item !== index);

newArray.push(index)
setShowStatus(newArray)

}
// console.log(showStatus)
const removeStatus=(index)=>{

  const newArray = showStatus.filter(item => item !== index);

  setShowStatus(newArray)
  }



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
<div className='productlisting-wrapper'>
        <div className='container-main'>
            <div className='second-sec-txt-wrap'>
                <h2>Exclusive Listings</h2>
                <p>Finding your dream home is only a few clicks away</p>
            </div>

            {/* <div className='property-listing-wrapper'>
              {props?.listings?.length>0 && props.listings.map((list)=>(
              <div className='property-thumb'>
                <Link to= {`/listing/${list.id}`}>
                   <div className='property-top-img'>
                     <img className='property-img' src={list.mainImage} alt="My Image" />
                   </div>

                   <div className='property-bottom-cnt'>
                      <span className='property-status' style={{backgroundColor:getColor(list.status)}}>{getStatus(list.status)}</span>
                      <h5 className='property-rate'>${list.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                      <p className='property-address'>{list.address}</p>
                      

                      <div className='property-btm-config-wraper'>
                         <div className='property-btm-config'>
                             <span className='property-config-icon'><img src={bed} /> <p>{list.bedrooms}</p></span>
                             <p>Bedroom</p>
                         </div>

                         <div className='property-btm-config'>
                             <span className='property-config-icon'><img src={paper} /> <p>{list.bathrooms}</p></span>
                             <p>Bathroom</p>
                         </div>

                         <div className='property-btm-config'>
                             <span className='property-config-icon'><img src={area} /> <p>{list.squareFeet}</p></span>
                             <p>Home Area</p>
                         </div>
                      </div>
                   </div>
                </Link>
              </div>))}
            </div> */}

            <div className='property-listing-wrapper property-bottom-cnt-change-wrapper'>
              {props.listings.length>0 && props.listings.map((list,index)=>(
              <div className='property-thumb' key={index}>
               {list.status==8&& <button onClick={()=>{
                handleShowStatus(index)
               }} className='open-property-modal'>Open</button>}
              
                <Link to= {`/listing/${list.id}`}>

                   <div className='property-bottom-cnt property-bottom-cnt-change'>
                      <div className='property-top-img'>
                         <span className='property-status' style={{backgroundColor:getColor(list.status)}}>{getStatus(list.status)}</span>
                         
                         <img className='property-img' src={list.mainImage} alt="My Image" />
                         <h5 className='property-rate'>${list.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                      </div>
                      <p className='property-address mls-top'>{list.mls_no}</p>
                      <p className='property-address'>{list.address}</p>

                      <div className='property-btm-config-wraper'>
                         <div className='property-btm-config'>
                             <span className='property-config-icon'><img src={bed} /> <p>{list.bedrooms}</p></span>
                             <p>Bedroom</p>
                         </div>

                         <div className='property-btm-config'>
                             <span className='property-config-icon'><img src={paper} /> <p>{list.bathrooms}</p></span>
                             <p>Bathroom</p>
                         </div>

                         <div className='property-btm-config'>
                             <span className='property-config-icon'><img src={area} /> <p>{list.squareFeet}</p></span>
                             <p>Home Area</p>
                         </div>
                      </div>

                      {showStatus.includes(index)&&
                        <div className='show-on-popup-wrapper'>
                          <div className='show-on-popup'>
                          <button onClick={(e)=>{
                            e.preventDefault()
                            removeStatus(index)
                          }}> x</button>
                          <div className='openhouse-popup-content'>
                              <p>Open House Date: {list.openHouseDate}</p>
                              <p>From: {list.openTime} | To: {list.closeTime}</p>
                          </div>
                          </div>      
                        </div>
                      }
                    
                      </div>

                </Link>
              </div>))}
            </div>
        </div>
</div>        
  )
}
export default Listing