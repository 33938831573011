import React from 'react'
import './ListingHeader.css'
const ListingHeader = (props) => {
  return (
    <div>   
        <div className='heade-for-inner-listing'>
    <div className='black-overlay'></div>
    <div className='container-main container-main-about-sec-tw inner-header-cntnt second-sec-txt-wrap'>
      <div className='banner-block-text'>
        <h2>{props.title}</h2>
        <p>{props.description}</p>
      </div>
    </div>
 </div>
 </div>
  )
}

export default ListingHeader