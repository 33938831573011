import React from 'react'
import './Hero.css'
import herohome from '../assets/images/hero-home.svg';
import kunal from '../assets/images/kunal.svg';

import ReactPlayer from 'react-player';


const Hero = () => {
  return (
    <div className='hero-wrapper'>
      <div className='container-main'>
      <div className='hero-home-cnt'>
        <h1>24k Realty Group<br/>is here for you.</h1>

        {/* <div className='kunal-area-hero'>
           <img className='kunal-img' src={kunal} alt="My Image" />

           <div className='kunal-inf-tag-wrapper'>
              <div className='kunal-inf-tag'>
                <h6>Kunal Bharatendu</h6>
                <p>Personal real estate corporation</p>
              </div>
           </div>
        </div> */}
      </div>
      </div>

      <div className='bg-video-hero'>
      <ReactPlayer className='player' url="/merged.mp4" playing loop muted/>
        {/* <ReactPlayer className="player" url='https://www.youtube.com/embed/y9j-BL5ocW8?si=lShAJjanOtTQRfJP' playing muted/> */}
        <div className='black-overlay'></div>
        {/* <img className='hero-home' src={herohome} alt="My Image" /> */}
      </div>
    </div>
  )
}

export default Hero