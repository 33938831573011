import Container from 'react-bootstrap/Container';
import React, { useState, useEffect } from 'react';
import fb from '../assets/images/social1.svg';
import insta from '../assets/images/social3.svg';
import "./Header.css"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../assets/images/24klogo.svg';
import logoRight from '../assets/images/logo-right-two.svg';
import phone from '../assets/images/phone.svg';
import { Link } from 'react-router-dom';

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false); // Added state for the expanded Navbar
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Remove the scroll event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check if the user has scrolled down (e.g., when scrolling beyond 100px)
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleNavToggle = () => {
    setExpanded(!expanded); // Toggle the expanded state
  };

  const closeNavbar = () => {
    setExpanded(false); // Close the navbar
  };

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  const handleTouchStart = () => {
    setShow(true);
  };

  const handleTouchEnd = () => {
    setShow(false);
  };

  return (
    <Navbar
      expand="lg"
      className={`nav-top-bar ${scrolled ? 'scrolled' : ''}`}
      expanded={expanded} // Set the expanded prop to manage the Navbar state
    >
      <div className='container-main'>
        <div className='nav-items-wrapper'>
          <Navbar.Brand>
            <div className='logo-flg--wrapper'>
              <Link to='/' onClick={closeNavbar}>
                <img src={logo} alt="My Image" />
              </Link>
            </div>

            <div className='nav-right-top'>
              <Link to='/' onClick={closeNavbar}>
                <img src={logoRight} alt="My Image" />
              </Link>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleNavToggle} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto nav-div-right-wrapper">
              <div className='nav-left-top'>

                <NavDropdown
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={handleTouchStart}
                 
               
                  title="Our Listings"
                  id="navbarScrollingDropdown"
                  show={show}
                >
                  <Link  to="/featured-listing" className='nav-link' onClick={closeNavbar}>
                    Featured Listings
                  </Link>
                  <NavDropdown.Divider />
                  <Link  to="/featured-listing" className='nav-link' onClick={closeNavbar}>
                    MLS Search
                  </Link>
                </NavDropdown>

                <Link to="/meet-our-team" className='nav-link' onClick={closeNavbar}>Meet Our Team</Link>
                <Link to="/accomplishments" className='nav-link' onClick={closeNavbar}>Accomplishments</Link>
                {/* <Link to="/exclusive-listings" className='nav-link' onClick={closeNavbar}>Exclusive Listings</Link> */}
              </div>

              <div className='nav-right-top'>
                <a href="https://www.facebook.com/RealtorKunalBharatendu" target='_blank'><img className='' src={fb} alt="My Image" /></a>
                <a href="https://www.instagram.com/24k_Realty_Group/" target='_blank'><img className='' src={insta} alt="My Image" /></a>
                <a href="tel:+17785924312" className='nav-link'>
                  <img src={phone} alt="My Image" />
                  778.592.4312
                </a>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </div>
    </Navbar>
  );
}

export default Header;
