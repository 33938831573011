
import { useEffect, useState } from "react";
import  "./Testinomial.css"
import { Button, Modal,Carousel,Card } from "react-bootstrap";

export default function Testimonial() {
    const [isMobile, setIsMobile] = useState(false)
    const [isSingleDevice,setIsSingleDevice]=useState(false)
    const handleResize = () => {

        if (window.innerWidth < 576){
          setIsSingleDevice(true)
        }
        else{
          setIsSingleDevice(false)
        }
        if (window.innerWidth < 767 && window.innerWidth >= 576) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
      }
      
      // create an event listener
      useEffect(() => {
        window.addEventListener("resize", handleResize)
      },[])
    const reduceReviews = (acc, cur, index) => {
        if(isSingleDevice){
          const groupIndex = Math.floor(index / 1);
          if (!acc[groupIndex]) acc[groupIndex] = [];
          acc[groupIndex].push(cur);
          console.log(acc);
          return acc;
        }
        if(isMobile){
          const groupIndex = Math.floor(index / 2);
          if (!acc[groupIndex]) acc[groupIndex] = [];
          acc[groupIndex].push(cur);
          console.log(acc);
          return acc;
        }
        const groupIndex = Math.floor(index / 3);
        if (!acc[groupIndex]) acc[groupIndex] = [];
        acc[groupIndex].push(cur);
        console.log(acc);
        return acc;
      };
    
      const reviews = [
        {
          id: 1,
          image: "https://images.unsplash.com/photo-1639747280804-dd2d6b3d88ac?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80",
          content: "I found this real estate website very helpful. It simplified my property search.",
          author: "John Smith"
        },
        {
          id: 2,
          image: "https://img.freepik.com/free-photo/smiling-caucasian-guy-with-beard-looking-happy_176420-18707.jpg?w=740&t=st=1696492432~exp=1696493032~hmac=dae95f5e4cb70a474035a94906d15537e6a337dde103b4e87e037ef83aa0bfdd",
          content: "The website's features are fantastic, making property hunting a breeze!",
          author: "David Williams"
        },
        {
          id: 3,
          image: "https://img.freepik.com/free-photo/portrait-beautiful-young-woman-standing-grey-wall_231208-10760.jpg?w=740&t=st=1696492491~exp=1696493091~hmac=432bc45d2edf37767ae5e5b56779b09ac92ab8f01f8bc879dcd0262ac07642bc",
          content: "I'm impressed with the quality of property listings on this website. Great job!",
          author: "Jane Johnson"
        },
        {
          id: 4,
          image: "https://img.freepik.com/free-photo/close-up-young-successful-man-smiling-camera-standing-casual-outfit-against-blue-background_1258-66609.jpg?w=740&t=st=1696492520~exp=1696493120~hmac=cd4557dcee475e4d3997f9d3b0f1388d19d72e20b97249e6cb689163c5c0a300",
          content: "The website's user experience is top-notch. It made my property search enjoyable!",
          author: "Michael Anderson"
        },
        {
          id: 5,
          image: "https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg?w=740&t=st=1696492571~exp=1696493171~hmac=7b3e2d888d72662600dbabe347ed82cc8998e295242e92a9e2acb9e85f42e73f",
          content: "I would recommend this website to anyone in search of real estate platform!",
          author: "Sarah Davis"
        },
        {
          id: 6,
          image: "https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?w=740&t=st=1696492599~exp=1696493199~hmac=20d34a83c6a6ebd724596314beef6aacebfe26c22551621f2518036bedf74386",
          content: "This website offers a variety of property options. I'm very satisfied with it!",
          author: "James Taylor"
        },
      ];
      
      // These reviews now have generic content while keeping the same images.
      
      
      // These reviews are now more generic and suitable for a general property listing/real estate website.
      
      
      // Now, the array contains real property reviews and real author names.
      
  return (
    <div className="testinomials">
      <Carousel >
          {reviews.reduce(reduceReviews, []).map((review, index) => (

            <Carousel.Item key={index}>
            <div className="d-flex justify-content-center" style={{gap:"50px"}}>
              {review.map((item, index) => {
                return (
                  <Card key={index} style={{ width: "25rem" }}>
                    <Card.Img variant="top" src={item.image} />
                    <Card.Body>
                     
                      
                <h6>{item.content} </h6>
                <h4>-{item.author}</h4>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
