import React, { useState } from 'react'
import './PropertyFind.css'
import arrow from '../assets/images/btn-arrow.svg';
import Accordion from 'react-bootstrap/Accordion';
import { Form } from 'react-router-dom';

import bgtow from '../assets/images/find-form-bg-2.svg';
import axios from 'axios';

const PropertyFind = () => {

    const [formData, setFormData] = useState({
        listingOptions: '',
        areaOptions: '',
        budget: '',
        trait: '',
        firstname: '',
        isLead:1,
        lastname: "",
    
        address1: "",
        isLead:true,


        provinceName: "",
        source: "Website",
        phone: "",
        parentId: null,
        createdAt: "",
        updatedAt: "",
        realtorId: null,
        propertyId: null,
        children: [],
        email: '',
        phone: '',
      });
      const url = process.env.REACT_APP_API_URL;
    const[formSubmitted,setFormSubmitted]=useState(false)
      const [validationErrors, setValidationErrors] = useState({

        name: '',
       
      });
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValidationErrors({
            
        })
        setFormData({ ...formData, [name]: value });
      };

      const selectListingOptions = [
     
        { value: 'Never', label: 'Never' },
        { value: 'First-time', label: 'First-time' },
        { value: 'Homeowner', label: 'Homeowner' },
        { value: 'Experienced', label: 'Experienced' },
        { value: 'HomeBuyerInvestor', label: 'Home Buyer / Investor' },
      ];

      const selectTraitOptions = [

        { value: "lot_size", label: "Lot size" },
        { value: "privacy", label: "Privacy" },
        { value: "affordability", label: "Affordability" },
        { value: "future_value", label: "Future value" },
        { value: "other", label: "Other" },
      ];
      
      const selectAreaOptions = [
   
        { value: 'Surrey', label: 'Surrey' },
        { value: 'Richmond', label: 'Richmond' },
        { value: 'Langley', label: 'Langley' },
        { value: 'Cloverdale', label: 'Cloverdale' },
        { value: 'SouthSurreyWhiteRock', label: 'South Surrey / White Rock' },
        { value: 'Other', label: 'Other' },
      ];
      const selectBudgetOptions = [
       
        { value: '$1,000,000', label: '$1,000,000' },
        { value: '$1,000,000 - $2,000,000', label: '$1,000,000 - $2,000,000' },
        { value: '>$2,000,000', label: '>$2,000,000' },
       
      ];
    
   
   
      const handleSubmit =async (e) => {
        e.preventDefault();
    
        const errors = {};
    
       
        if (!formData.firstname) {
          errors.firstname = 'Name is required';

        }
    
        else if (!formData.email && !formData.phone) {
          errors.email = 'Either Email/Contact is required';
        }
    
       
    
        if (Object.keys(errors).length > 0) {
            console.log(errors)
          setValidationErrors(errors);
        } else {
            const response = await axios.post(`${url}api/contacts/create`, formData)
          // Form data is valid, you can perform any further actions here
          console.log('Form data submitted:', formData);

          setFormData({
            
                listingOptions: '',
                areaOptions: '',
                budget: '',
                traits: '',
                firstname: '',
                isLead:1,
                lastname: "",
                birthDate: "",
                address1: "",
                isLead:true,
                address2: "",
                city: "",
                provinceName: "",
                source: "",
                phone: "",
                parentId: null,
                createdAt: "",
                updatedAt: "",
                realtorId: null,
                propertyId: null,
                children: [],
                email: '',
                phone: '',
              
          })
          setFormSubmitted(true)

        }
      };
        
  return (
    <div className='step-form-wrapper'>
        <div className='container-main'>
            <div className='second-sec-txt-wrap'>
            
                <h2>Let us know about this enquires below</h2>
                <p> Our driven team will work tirelessly to ensure that every lead is pursued and no detail is overlooked. Above all, we strive to maintain complete transparency with our clients during negotiations and once a deal is finalized, we look forward to serving your needs in the future.</p>
            </div>
        </div>

        <div className='form-wrapper'>
            
                <div className='form-find-wrapper'>
                    <div className='form-find-wrapper-left'>
                        <div className='container-main form-container-div'>
                        {!formSubmitted?<form onSubmit={handleSubmit}>
                            <div className='acordian-wrapper'>
                      
                                <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Have you bought a home before?</Accordion.Header>
                                 
                                    <Accordion.Body>
    {/* Radio buttons for Accordion Section 1 */}
    {/* Update name and values for each radio button */}
    <div className='accordian-radio-wraper'>
  {selectListingOptions.map((option) => (
    <div className='radio-wrapper' key={option.value}>
      <input
        type='radio'
        name='listingOptions'
        value={option.value}
        checked={formData.listingOptions === option.value}
        onChange={() => handleInputChange({ target: { name: 'listingOptions', value: option.value } })}
      />
      <label onClick={() => handleInputChange({ target: { name: 'listingOptions', value: option.value } })}>
        {option.label}
      </label>
    </div>
  ))}
</div>

  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Which area are you interested in?</Accordion.Header>
                                    <Accordion.Body>
                                    <div className='accordian-radio-wraper'>
      {selectAreaOptions.map((option) => (
        <div className='radio-wrapper' key={option.value}>
          <input
            type='radio'
            name='areaOptions'
            value={option.value}
            checked={formData.areaOptions === option.value}
            onChange={() => handleInputChange({ target: { name: 'areaOptions', value: option.value } })}
          />
          <label onClick={() => handleInputChange({ target: { name: 'areaOptions', value: option.value } })}>{option.label}</label>
        </div>
      ))}
    </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What is your budget?</Accordion.Header>
                                    <Accordion.Body>
                                    <div className='accordian-radio-wraper'>
      {selectBudgetOptions.map((option) => (
        <div className='radio-wrapper' key={option.value}>
          <input
            type='radio'
            name='budget'
            value={option.value}
            checked={formData.budget === option.value}
            onChange={() => handleInputChange({ target: { name: 'budget', value: option.value } })}
          />
          <label onClick={() => handleInputChange({ target: { name: 'budget', value: option.value } })}>{option.label}</label>
        </div>
      ))}
    </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>What are the most desirable traits you look for in a property?</Accordion.Header>
                                    <Accordion.Body>
                                    <div className='accordian-radio-wraper'>
      {selectTraitOptions.map((option) => (
        <div className='radio-wrapper' key={option.value}>
          <input
            type='radio'
            name='trait'
            value={option.value}
            checked={formData.trait === option.value}
            onChange={() => handleInputChange({ target: { name: 'trait', value: option.value } })}
          />
          <label onClick={() => handleInputChange({ target: { name: 'trait', value: option.value } })}>{option.label}</label>
        </div>
      ))}
    </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                            </div>

                            <div className="btm-input-sec">
          <input
            type="text"
            name="firstname"
            placeholder="Enter your name here"
            value={formData.firstname}
            onChange={handleInputChange}
          />
          <span className="error-message name-error">{validationErrors.firstname}</span>
          <input
            type="text"
            name="email"
            placeholder="Enter your email here"
            value={formData.email}
            onChange={handleInputChange}
          />
          <span className="error-message email-phone-error">{validationErrors.email}</span>
          <input
            type="text"
            placeholder="Enter your contact here"
            name="phone"
           
            value={formData.phone}
            onChange={handleInputChange}
          />
        

          {/* Add radio buttons and checkboxes here */}
        </div>

                            <div className='submit-wrapper'>
                            <button type="submit"  className='button-arrow'>Submit <img className='arrow-n-btn' src={arrow} alt="My Image" /></button>
                            </div>
                        </form> :
                        <form className='form-submit-message'>
                          <img className='tick-img' src='/tick.png' alt="My Image" />
                          <p>Form Submitted successfully</p>
                          </form>}
                        </div>  
                    </div>
                    <div className='form-find-wrapper-right'>
                        <img className='find-form-right-img' src={bgtow} alt="My Image" />
                    </div>
                </div> 
            
        </div>
    </div>
  )
}

export default PropertyFind



