import React, { useRef } from 'react'
import { Team } from '../Team'

import './About.css'

import arrowlong from '../../assets/images/arrow-long.svg';

import fb from '../../assets/images/social1.svg';
import twitter from '../../assets/images/social2.svg';
import insta from '../../assets/images/social3.svg';
import profile from '../../assets/images/profile.png';
import profilee from '../../assets/images/profile2.png';
import tejinder from '../../assets/images/tejinder1.jpg' 
import kunal from '../../assets/images/kunal1.jpg'
import dummy from '../../assets/images/profile4.png';
import { Link } from 'react-router-dom';
import Testimonial from './Testinomial';
import logo from '../../assets/images/24klogo.svg';
const Meetourteam = () => {
  const scollToRef = useRef();
  return (
    <div className='meet-our-team-page'>
      <div className='heade-for-inner-listing header-about-inner'>
        <div className='black-overlay'></div>
        <div className='container-main container-main-about-sec-tw inner-header-cntnt second-sec-txt-wrap'>
          <div className='banner-block-text'>
            <h2>Meet Our Team</h2>
            <p>24k Realty Group is a multifaceted corporate entity providing services that cover Real Estate, Mortgages, Insurance, Financing (private lending) and Land Development.</p>
          </div>
        </div>
      </div>

      <div className="meet-team-sec meet-our-team-on-page meet-our-team-on-page-indiv"  ref={scollToRef}>
        <div className="container-main">
          <div className='second-sec-txt-wrap'>
              <h2>Meet Our Team</h2>
              <p>Here is my team who have realy Good experience in this field.</p>
          </div>

          <div className="team-container-wrapper">
            <div className="team-card-container team-card-container-indi">
              <div className="card">
                <div className="imgBx">
                  <img
                    src={kunal }
                    alt=""
                  />
                </div>
                <div className="content">
                <div className="contentBx">
                    <h3>
                      Kunal Bharatendu<br />
                      <span>Founder</span>
                    </h3>
                </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="contentBx">
                    <h3>
                      Kunal Bharatendu<br />
                      <span>Founder</span>
                    </h3>
                </div>
              </div>

              <div className="card">
                <div className="imgBx">
                <img
                    src={tejinder}
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      Tejinder Punia <br />
                      <span>Realtor</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contentBx">
                    <h3>
                      Tejinder Punia <br />
                      <span>Realtor</span>
                    </h3>
                  </div>
              </div>

              <div className="card">
                <div className="imgBx">
                  <img
                    src="./team-m-f.jpg"
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      coming soon<br />
                      <span>Realtor</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contentBx">
                    <h3>
                      coming soon<br />
                      <span>Realtor</span>
                    </h3>
                  </div>
              </div>

              <div className="card">
                <div className="imgBx">
                  <img
                    src="./team-m-l.jpg"
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      coming soon<br />
                      <span>Administrative Assistant</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contentBx">
                    <h3>
                      coming soon<br />
                      <span>Administrative Assistant</span>
                    </h3>
                </div>
              </div>
            </div>
          </div>



          <div className='second-sec-txt-wrap mt-5'>
              <h2>Meet Our Team</h2>
              <p>Here is my team who have realy Good experience in this field.</p>
          </div>
          
          <div className="team-container-wrapper">
            <div className="team-card-container team-card-container-indi profile-cards-container">
              <div className="profile-card-main">
                <div className="imgBx">
                  <img
                    src={profile}
                    alt=""
                  />
                </div>
                <div className='social-icons'>
                  <ul className="sci">
                      <li>
                        <a href="#">
                          <img className='' src={fb} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={twitter} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={insta} alt="My Image" />
                        </a>
                      </li>
                    </ul>
                </div>
                <div className='profile-content'>
                  <h2>Kunal Bharattendu</h2>
                  <span>Founder</span>
                </div>
              </div>

              <div className="profile-card-main">
                <div className="imgBx">
                <img
                    src={tejinder}
                    alt=""
                  />
                </div>

                <div className='social-icons'>
                  <ul className="sci">
                      <li>
                        <a href="#">
                          <img className='' src={fb} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={twitter} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={insta} alt="My Image" />
                        </a>
                      </li>
                    </ul>
                </div>
                <div className='profile-content'>
                  <h2>Tejinder Punia</h2>
                  <span>Realtor</span>
                </div>
                
              </div>

              <div className="profile-card-main">
                <div className="imgBx">
                  <img
                    src={logo}
                    alt=""
                  />
                </div>
                <div className='social-icons'>
                  <ul className="sci">
                      <li>
                        <a href="#">
                          <img className='' src={fb} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={twitter} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={insta} alt="My Image" />
                        </a>
                      </li>
                    </ul>
                </div>
                <div className='profile-content'>
                  <h2>coming soon </h2>
                  <span>Realtor</span>
                </div>
                
              </div>

              <div className="profile-card-main">
                <div className="imgBx">
                  <img
                    src={logo}
                    alt=""
                  />
                </div>
                <div className='social-icons'>
                  <ul className="sci">
                      <li>
                        <a href="#">
                          <img className='' src={fb} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={twitter} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={insta} alt="My Image" />
                        </a>
                      </li>
                    </ul>
                </div>
                <div className='profile-content'>
                  <h2>coming soon </h2>
                  <span>Administrative Assistant</span>
                </div>
                
              </div>     
            </div>
          </div>



        </div>
      </div>

    </div>
  )
}

export default Meetourteam