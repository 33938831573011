import React, { useState, useEffect, useRef } from 'react';
import "./Instfeeds.css";
import insta from '../assets/images/insta.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';

export const Instfeeds = () => {
  const [imageFeeds, setImageFeeds] = useState([]);
  const [reFreshToken, setRefreshToken] = useState('')


  const [accessToken, setAccessToken] = useState('IGQWRNTUFXZAHk1djRTY2pNVlVZAX3FLSkFLdWx3OGVkTXdFWkZArcXVXRzFfa3dQZAzZApN0Q0YW5YbG9DSldOZA3FuSlJwYkVRa1hfUTZAFRkItX0VzVnZAGRlJpTGp0M3YyX2M4TlRmQmd2VFRPUWQ5Q2dDVFY2NEdhYmsZD')
  const canvasRef = useRef(null);



 

  useEffect(() => {
    const fetchInstagramImages = async () => {
      try {
        // const response = await fetch(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink&access_token=IGQWRPVW1sRWthT2xKeVZAPb29rRkdxdHdpUm51WlRjNkktckY1T0JpbHJUZAG5MTzJPYlRUWFdvcVYzOUYwMGg4SlBtVm9WZAWJJMnNRa0ZAKTVdZAMWJQcWxhb09uWVhMTWZAidnVwcUVHaXB6bVN4N0hUU3FvNG15YXcZD`);        const data = await response.json();
        const response = await fetch(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink&access_token=${accessToken}`);
        const data = await response.json();
        const imageFeedsData = data.data;
     //   console.log("imageFeeds" ,imageFeedsData);
        setImageFeeds(imageFeedsData);
      } catch (error) {
        console.error('Error fetching Instagram images:', error);
      }
    };

    fetchInstagramImages();
  }, []);


  return (
    <div className="instafeeds-wrapper">
      <div className="insta-feeds-wrapper">
        <div className="instafeeds-follow-right">
          <div className="container-main">
            <div className="instafeeds-follow-right-cnt">
              <h2>Insta Feeds</h2>
              <p>Go and check out our insta feeds</p>
              <a
                href="https://www.instagram.com/24k_Realty_Group/"
                target="_blank"
                className="button-arrow btn-yellow"
              >
                <img className="arrow-n-btn" src={insta} alt="Instagram Logo" />{" "}
                Follow us on Instagram
              </a>
            </div>
          </div>
        </div>
        <div className="instafeeds-left">
          {imageFeeds && imageFeeds.map((feed) => (
            <div key={feed.id} className="insta-feed">
              {feed.media_type === "IMAGE" ? (
                <img src={feed.media_url} alt="Instagram Feed" />
              ) : (

                <video style={{ objectFit: "cover" }} width="100%" height="100%" controls>
                  <source src={feed.media_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ))}
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
        </div>
      </div>
    </div>
  );
}

export default Instfeeds;
