import React from "react";
import "./Team.css";
import { Link } from "react-router-dom";
import arrowlong from '../assets/images/arrow-long.svg';
import tejinder from '../assets/images/tejinder1.jpg' 
import kunal from '../assets/images/kunal1.jpg'
import fb from '../assets/images/social1.svg';
import twitter from '../assets/images/social2.svg';
import insta from '../assets/images/social3.svg';
import profile from '../assets/images/profile.png';
import profilee from '../assets/images/profile2.png';
import profileee from '../assets/images/profile3.png';
import logo from '../assets/images/24klogo.svg';


export const Team = () => {
  return (
    <div className="meet-team-sec meet-team-sec-tw meet-our-team-on-page-indiv">
      <div className="container-main">
        <div className="team-container-wrapper ">
          <div className="team-left-content">
            <h2>Meet Our Team</h2>
            <p>
              24k Realty Group is a multifaceted corporate entity providing
              services that cover Real Estate, Mortgages, Insurance, Financing
              (private lending) and Land Development. Founded in 2021 by father
              and sons Bharat, Kunal and Dev Bharatendu, the company strives to
              interlink its services to provide a holistic solution to client
              needs.
            </p>
            <Link to="/meet-our-team" className="button-arrow btn-yellow">
              Know more <img className='arrow-n-btn block-arrow arow-know-more' src={arrowlong} alt="My Image" />
            </Link>
          </div>

          <div className="team-card-container team-card-container-indi">
            <div className="card">
              <div className="imgBx">
                <img
                  src={kunal}
                  alt=""
                />
              </div>
              <div className="content">
                <div className="contentBx">
                  <h3>
                    Kunal Bharatendu<br />
                    <span>Founder</span>
                  </h3>
                </div>
                <ul className="sci">
                  <li>
                    <a href="#">
                      <img className='' src={fb} alt="My Image" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img className='' src={twitter} alt="My Image" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img className='' src={insta} alt="My Image" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="contentBx">
                <h3>
                  Kunal Bharatendu<br />
                  <span>Founder</span>
                </h3>
              </div>
            </div>

            <div className="card">
              <div className="imgBx">
                <img
                  src={tejinder}
                  alt=""
                 
                
                />
              </div>
              <div className="content">
                <div className="contentBx">
                  <h3>
                    Tejinder Punia<br />
                    <span>Realtor</span>
                  </h3>
                </div>
                <ul className="sci">
                  <li>
                    <a href="#">
                      <img className='' src={fb} alt="My Image" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img className='' src={twitter} alt="My Image" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img className='' src={insta} alt="My Image" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="contentBx">
                <h3>
                  Tejinder Punia<br />
                  <span>Realtor</span>
                </h3>
              </div>
            </div>

            <div className="card">
              <div className="imgBx">
                <img
                  src="./team-m-f.jpg"
                  alt=""
                />
              </div>
              <div className="content">
                <div className="contentBx">
                  <h3>
                    coming soon<br />
                    <span>Realtor</span>
                  </h3>
                </div>
                <ul className="sci">
                  <li>
                    <a href="#">
                      <img className='' src={fb} alt="My Image" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img className='' src={twitter} alt="My Image" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img className='' src={insta} alt="My Image" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="contentBx">
                <h3>
                  coming soon<br />
                  <span>Realtor</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
