import React, { useEffect, useState } from 'react'
import '../Feature-Listing/FeaturedListing.css'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import { Carousel, Modal } from 'react-bootstrap';
import ListingHeader from '../ListingHeader/ListingHeader'
import { Form } from 'react-bootstrap'
import Select from 'react-select';
import FeatureChild from '../Feature-Listing/FeatureChild';
import axios from 'axios';
import avatar from '../../assets/images/avatar.svg';
import arrowlong from '../../assets/images/arrow-long.svg';

const FeatureListing = (props) => {

  const[toggle,setToggle]=useState('all')
  const[listings,setListings]=useState(props.listings)
  const [searchQuery, setSearchQuery] = useState([]);
  const [selectedOption, setSelectedOption] = useState(  { value: '1', label: 'Default Order' },);
  const options = [
    { value: '1', label: 'Default Order' },
    { value: '2', label: 'Price - Low to High' },
    { value: '3', label: 'Price - High to Low' },
  ];
  const url = process.env.REACT_APP_API_URL;
  const[formSubmitted,setFormSubmitted]=useState(false)
    const [validationErrors, setValidationErrors] = useState({

      name: '',
     
    });
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    listingOptions: '',
    areaOptions: '',
    budget: '',
    traits: '',
    firstname: '',
    isLead:1,
    lastname: "",

    address1: "",
    isLead:true,
   
    provinceName: "",
    source: "Website",
    phone: "",
    parentId: null,
    createdAt: "",
    updatedAt: "",
    realtorId: null,
    propertyId: null,
    children: [],
    email: '',
    phone: '',
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
useEffect(()=>{
if(toggle=='active'){
 const filtered= props.listings.filter((object) => object.status == 1)
 setListings(filtered)
}
else if(toggle=='sold'){
  const filtered= props.listings.filter((object) => object.status == 11)
  setListings(filtered)
 }
 else {
  setListings(props.listings)
 }

},[toggle])

const colourStyles = {
  control: styles => ({ ...styles, background: 'none', border: 'none' }),
  menu:styles => ({ ...styles, background: '#fff' }),
  input:styles => ({ ...styles, width: '190px' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    color: isSelected ? '#fff' : '#fff',
    background:isSelected?"#c79a4f":"#222",
  }),
  singleValue: (styles, { data, isDisabled, isFocused }) => ({
    ...styles,
    color: '#000', // Set the text color of the selected value to white
  }),
};
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setValidationErrors({
      
  })
  setFormData({ ...formData, [name]: value });
};
const handleSubmit =async (e) => {
  e.preventDefault();

  const errors = {};

 
  if (!formData.firstname) {
    errors.firstname = 'Name is required';

  }



 

  if (Object.keys(errors).length > 0) {
      console.log(errors)
    setValidationErrors(errors);
  } else {
      const response = await axios.post(`${url}api/contacts/create`, {...formData,
       
    
    })
    // Form data is valid, you can perform any further actions here
    console.log('Form data submitted:', formData);

    setFormData({
      
          listingOptions: '',
          areaOptions: '',
          budget: '',
          traits: '',
          firstname: '',
          isLead:1,
          message:'',
          lastname: "",
          birthDate: "",
          address1: "",
          isLead:true,
          address2: "",
          city: "",
          provinceName: "",
          source: "",
          phone: "",
          parentId: null,
          createdAt: "",
          updatedAt: "",
          realtorId: null,
          propertyId: null,
          children: [],
          email: '',
          phone: '',
        
    })
    setFormSubmitted(true)

  }
};


useEffect(()=>{
 
  setListings(props.listings)
  },[props])


  // Create a state to store the selected option


  // Handle the selection change
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  
    // Create a copy of the listings array to avoid mutating the state directly
    const updatedListings = [...listings];
  
    if (selectedOption.value === '2') {
      // Sort by Price - Low to High
      updatedListings.sort((a, b) => a.price - b.price);
    } else if (selectedOption.value === '3') {
      // Sort by Price - High to Low
      updatedListings.sort((a, b) => b.price - a.price);
    } else {
      updatedListings.sort((a, b) => b.id - a.id);
      // Default Order (e.g., don't apply any sorting)
      // You can also fetch the default order from your original data if needed.
    }
  
    // Update the listings state with the sorted array
    setListings([...updatedListings]); // Make sure to spread the sorted array when updating the state
  };
 const handleSEarch=(e)=>{
  //console.log("here")
  if(e.target.value=="" || e.target.value.length==1){
    return
  }
  const filteredProperties = listings.filter((property) => {
    const searchText = e.target.value.toLowerCase();
   
    return (
      property?.mls_no?.toLowerCase().includes(searchText) ||
      property?.propertyType?.toLowerCase().includes(searchText) ||
 
      property?.price?.toFixed(2).includes(searchText) ||
      property?.squareFeet?.toLowerCase().includes(searchText) ||
      property?.address?.toLowerCase().includes(searchText) ||
      (property?.lawyer && property?.lawyer?.name.toLowerCase().includes(searchText)) ||
      (property?.realtor && property?.realtor?.name.toLowerCase().includes(searchText)) 
     
    );
  });
  setSearchQuery(filteredProperties)
  console.log(filteredProperties,"fdhffddffd")
}
  
  return (
    <div className='listing-indi-page'>
      <ListingHeader 
      title="Featured Listings"
      description="Below are our team listings, updated from the MLS & non MLS. For more details on homes, condos, or townhomes, request info or schedule a showing from each listing's page. Get disclosures, past sales, and recent prices. Make informed decisions with us."
      />
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                <div className='single-page-right-form'>
                     <div className='listing-form-top'>
                            <div className='listing-form-top-cnt'>
                                  <h5>Request More Info About This Listing</h5>
                                  <button onClick={handleClose}>x</button>
                            </div>
                     </div>

                     <div className='listing-form-top listing-form-top-sec'>
                            <div className='listing-form-top-cnt'>
                                  <h6>Complete the form below to request more info about this listing.</h6>
                            </div>
                     </div>

                     <div className='all-form-meterial'>

                            <Form className='listing-form'>
                                <div className='form-half-column-wrapper'>
                                    <div className='form-half-column'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" 
                                            
                                            value={formData.firstname}
                                            onChange={handleInputChange}
                                            name="firstname"
                                            placeholder="Name" />
                                        </Form.Group>
                                    </div>

                                    <div className='form-half-column'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="tel" 
                                                name="phone"
                    
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                            
                                            placeholder="Phone" />
                                        </Form.Group>
                                    </div>
                                </div>
                            
                            
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="email" 
                                   name="email"
                                   value={formData.email}
                                   onChange={handleInputChange}   

                                placeholder="Email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" 
                                name="message"
                                
                                rows={3}
                                placeholder="How can we help you?"  />
                            </Form.Group>
                                <Form.Select aria-label="Default select example">
                                <option>Contact Preference</option>
                                <option value="1">Phone</option>
                                <option value="2">Email</option>
                                </Form.Select>
                            </Form>

                            <div className='listing-form-check'>
                                {["I consent to receive emails containing real estate related information from this site's operators. I understand that I may unsubscribe at any time."].map((type) => (
                                    <div key={`default-${type}`} className="mb-3">
                                    <Form.Check 
                                        type={type}
                                        id={`default-${type}`}
                                        label={`${type}`}
                                    />
                                    </div>
                                ))}
                      </div>

                      <Button  onClick={handleSubmit} type="submit" className='listing-form-btn'>Request Info <img className='arrow-n-btn' src={arrowlong} alt="My Image" /></Button>
                    </div>
                </div>
                </Modal.Body>
            </Modal>
      <div className='featured-listing-page-two-columns'>
        <div className='container-main container-main-about-sec-tw'>
         <div className='featured-listing-page-left-column'>
            <div className='property-filter-bar'>

                <div className='featured-listingcolumn-top featured-listingcolumn-top-left'>
                   <div className='featured-listingcolumn-header'>
                       <h5>Featured Listings</h5>
                       <p>{new Date().toString().slice(0,10)}</p>
                   </div>

                   <div className='featured-listingcolumn-footer-wrapper'>
                      <div className='featured-listingcolumn-footer'>
                        <h6><span>10</span>Active</h6>
                      </div>

                      <div className='featured-listingcolumn-footer'>
                        <h6><span>5</span>Sold</h6>
                      </div>
                   </div>
                </div>
                
                <div className='property-filter-bar-wrapper'>
                        <div className='counting-filter'>
                          <p>{listings?.length} Results</p>
                        </div>
                        <div className='tab-filter'>
                    <button
                        className={`tab-button ${toggle === 'all' ? 'active' : ''}`}
                        onClick={() => {
                            setToggle('all');
                        }}
                    >
                        All
                    </button>
                    <button
                        className={`tab-button ${toggle === 'active' ? 'active' : ''}`}
                        onClick={() => {
                            setToggle('active');
                        }}
                    >
                        Active
                    </button>
                    <button
                        className={`tab-button ${toggle === 'sold' ? 'active' : ''}`}
                        onClick={() => {
                            setToggle('sold');
                        }}
                    >
                        Sold
                    </button>
                 </div>

                 <div className='tab-select'>
                      <p>Sort By:</p>
                      <Select
                        options={options}
                      
                        value={selectedOption}
                        styles={colourStyles}
                        onChange={handleSelectChange}
                        
                      />
                  </div>
                </div>
                
                <FeatureChild listings={listings}/>
            </div>
         </div>

         <div className='featured-listing-page-right-column'>
                <div className='featured-listingcolumn-top'>
                   <div className='featured-listingcolumn-header'>
                       <h5>Search All Area Listings</h5>
                   </div>

                   <div className='featured-listingcolumn-footer-wrapper featured-listingcolumn-footer-wrapper-btn'>
                   <div className='featured-listingcolumn-footer featured-listingcolumn-footer-search'>
                        <input type='text'    
       onChange={handleSEarch} placeholder='Enter Location, Postal Code, Address or MLS® #'/>

                     
                        {searchQuery.length>0&& searchQuery?.map((search, index) => (
                             <div className='mls-search-drop'>
    <p key={index}>
      {console.log("here")}
      <Link to={`/listing/${search.id}`}>{search.address}</Link>
    </p>
    </div>
  ))}
                        
                        <button className='ask-question-btn'>Search Homes</button>
                      </div>
                    
                   </div>
                </div>

                <div className='featured-listingcolumn-top'>
                   <div className='featured-listingcolumn-header featured-listingcolumn-header-center'>
                      <img src='/i.svg'/>
                       <h4>Agent Connect</h4>
                   </div>

                   <div className='featured-listingcolumn-footer-wrapper featured-listingcolumn-footer-wrapper-btn'>
                      <div className='featured-listingcolumn-footer'>
                        <h6>Need more info?</h6>
                        <button onClick={()=>setShow(true)} className='ask-question-btn'>Ask a Question</button>
                      </div>
                   </div>
                </div>
         </div>

         </div>
      </div>

    </div>
  )
}

export default FeatureListing