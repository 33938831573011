import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import PropertyFind from './components/PropertyFind';
import Accomplishment from './components/Accomplishment';
import Listing  from './components/Listing';
import BookAppointment from './components/BookAppointment';
import { Instfeeds } from './components/Instfeeds';
import { Route,Routes} from 'react-router-dom';
import About from './components/MeetOurTeam/About';
import Meetourteam from './components/MeetOurTeam/Meetourteam';
import { useEffect, useState } from 'react';
import ListingSingle from './components/IndividualListing/ListingSingle';
import { Team } from './components/Team';

import { IndividualAccomplishment } from './components/IndividualAccomplishment/IndividualAccomplishment';
import FeatureListing from './components/Feature-Listing/FeatureListing';
import ExclusiveListing from './components/ExclusiveListing/ExclusiveListing';
import ScrollToTop from './components/ScrollToTop';

function App() {
const [listings,setListings]=useState([])

useEffect(()=>{
getListingdata()

},[])

const getListingdata=async()=>{
const res = await axios.get("https://24krealty.nvinfobase.com/api/property")
setListings(res.data)
}

  return (
    <div className="App">
<ScrollToTop/>
      <Header />
      <Routes>

        <Route path="/" exact element={
          <>
            <Hero/>
            <Team/>
            <PropertyFind/>
            <Accomplishment slider={true}/>
            <Listing listings={listings}/>
            <Instfeeds/>
            <BookAppointment/>
          </>
        }/>

        <Route path="/listing/:id" exact element={
           <ListingSingle listings={listings}/>
        } />

        <Route  path='/meet-our-team' exact element={
            <>
              <About/>
            </>
        }/>

        <Route  path='/featured-listing' exact element={
            <>
              <FeatureListing listings={listings}/>
            </>
        }/>

        <Route  path='/accomplishments' exact element={
            <>
              <Accomplishment slider={false}/>
            </>
        }/>

        <Route  path='/exclusive-listings' exact element={
            <>
              <ExclusiveListing listings={listings}/>
            </>
        }/>

        <Route  path='/meet-teams' exact element={
            <>
              <Meetourteam/>
            </>
        }/>

      </Routes>

      <Footer />
    </div>
  );
}

export default App;


